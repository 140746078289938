<template>
  <div>
    <div class="all">
      <div class="progress">
        <div
          @click="progressIndex = 0"
          :class="progressIndex == 0 ? 'step1Select' : 'step1'"
        >
          <div class="progressText">育苗阶段</div>
        </div>
        <div class="progressDot">
      
          <div class="point" v-for=" (itee,index) in 6" :key="index"></div>
        </div>
        <div
          @click="progressIndex = 1"
          class="step2"
          :class="progressIndex == 1 ? 'step2Select' : 'step1'"
        >
          <div class="progressText">坡养阶段</div>
        </div>
        <div class="progressDot">
          <div class="point" v-for=" (itee,index) in 6" :key="index"></div>
        </div>
        <div
          @click="progressIndex = 2"
          class="step3"
          :class="progressIndex == 2 ? 'step3Select' : 'step1'"
        >
          <div class="progressText">育肥阶段</div>
        </div>
      </div>
      <div class="stepDetailContainer">
        <div class="progressLeft"></div>
        <!-- 主体1 -->
        <div class="stepDetail" v-if="progressIndex == 0">
          <div class="gotoleft" @click="goto(2)"></div>
          <div class="gotoright" @click="goto(1)"></div>
          <div class="img">
            <img
              v-if="stageList[progressIndex].thumbnailImageUrl"
              :src="stageList[progressIndex].thumbnailImageUrl"
              alt=""
            />
            <img
              v-if="!stageList[progressIndex].thumbnailImageUrl"
              src="https://asset.fsytss.com/trace/wcj/default_state_1.png"
              alt=""
            />
          </div>

          <div class="date" v-if=" stageList[progressIndex].popTime || stageList[progressIndex].pushTime">
            <div class="dateText" v-if="stageList[progressIndex].pushTime">
              入栏日期：  {{ stageList[progressIndex].formatpushTime }}
            </div>
            <div class="dateText" v-if="stageList[progressIndex].popTime">
              出栏日期：  {{ stageList[progressIndex].formatpopTime }}
            </div>
          </div>
          <div class="baseInfocontainer">
            <div class="infoitem">
              <div class="key">养殖天数</div>
              <div class="value">{{ stageList[progressIndex].cycleDay }}</div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">
                养
                <div style="width: 6.5px" />
                殖
                <div style="width: 7px" />
                户
              </div>
              <div class="value">
                {{
                  stageList[progressIndex].breedingFarmer
                    ? stageList[progressIndex].breedingFarmer
                    : "个体户"
                }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">
                养
                <div style="width: 6.5px" />
                殖
                <div style="width: 7px" />
                场
              </div>
              <div class="value" style="color: #d82a1f"   @click="onClickFarm(stageList[0])">
                {{
                  stageList[0].farmManagerName
                    ? stageList[progressIndex].farmManagerName
                    : "个体户"
                }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">
                圈
                <div style="width: 6.5px" />
                舍
                <div style="width: 7px" />
                码
              </div>
              <div class="value">
                {{
                  stageList[progressIndex].farmManagerCircleHouse
                    ? stageList[progressIndex].farmManagerCircleHouse
                    : "个体户"
                }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">喂食饲料</div>
              <div class="value">
                {{ stageList[progressIndex].feedingName }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">疫苗接种</div>
              <div class="value">
                {{ stageList[progressIndex].vaccineName }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div
              class="openDialog"
              @click="showDialog(stageList[progressIndex].id)"
            >
              饲养情况
            </div>
          </div>
        </div>
        <!-- 主体2 -->
        <div class="stepDetail" v-if="progressIndex == 1">
          <div class="gotoleft" @click="goto(0)"></div>
          <div class="gotoright" @click="goto(2)"></div>
          <div class="img">
            <img
              v-if="stageList[progressIndex].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              :src="stageList[progressIndex].thumbnailImageUrl"
              alt=""
            />
            <img
              v-if="!stageList[progressIndex].thumbnailImageUrl"
              style="width: 100%; height: 100%"
              src="https://asset.fsytss.com/trace/wcj/default_state_2.png"
              alt=""
            />
          </div>

         
          <div class="date" v-if=" stageList[progressIndex].popTime || stageList[progressIndex].pushTime">
            <div class="dateText" v-if="stageList[progressIndex].pushTime">
              入栏日期：  {{ stageList[progressIndex].formatpushTime }}
            </div>
            <div class="dateText" v-if="stageList[progressIndex].popTime">
              出栏日期：  {{ stageList[progressIndex].formatpopTime }}
            </div>
          </div>
          <div class="baseInfocontainer">
            <div class="infoitem">
              <div class="key">养殖天数</div>
              <div class="value">{{ stageList[progressIndex].cycleDay }}</div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">
                养
                <div style="width: 6.5px" />
                殖
                <div style="width: 7px" />
                户
              </div>
              <div class="value">
                {{
                  stageList[progressIndex].breedingFarmer
                    ? stageList[progressIndex].breedingFarmer
                    : "个体户"
                }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">
                养
                <div style="width: 6.5px" />
                殖
                <div style="width: 7px" />
                场
              </div>
              <div class="value" style="color: #d82a1f"   @click="onClickFarm(stageList[1])">
                {{
                  stageList[1].farmManagerName
                    ? stageList[progressIndex].farmManagerName
                    : "个体户"
                }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">
                圈
                <div style="width: 6.5px" />
                舍
                <div style="width: 7px" />
                码
              </div>
              <div class="value">
                {{
                  stageList[progressIndex].farmManagerCircleHouse
                    ? stageList[progressIndex].farmManagerCircleHouse
                    : "个体户"
                }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">喂食饲料</div>
              <div class="value">
                {{ stageList[progressIndex].feedingName }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">疫苗接种</div>
              <div class="value">
                {{ stageList[progressIndex].vaccineName }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div
              class="openDialog"
              @click="showDialog(stageList[progressIndex].id)"
            >
              饲养情况
            </div>
          </div>
        </div>
        <!-- 主体3 -->
        <div class="stepDetail" v-if="progressIndex == 2">
          <div class="gotoleft" @click="goto(1)"></div>
          <div class="gotoright" @click="goto(0)"></div>
          <div class="img">
            <img
              v-if="stageList[progressIndex].thumbnailImageUrl"
              :src="stageList[progressIndex].thumbnailImageUrl"
              alt=""
            />
            <img
              v-if="!stageList[progressIndex].thumbnailImageUrl"
              src="https://asset.fsytss.com/trace/wcj/default_state_3.png"
              alt=""
            />
          </div>

        
          <div class="date" v-if=" stageList[progressIndex].popTime || stageList[progressIndex].pushTime">
            <div class="dateText" v-if="stageList[progressIndex].pushTime">
              入栏日期：  {{ stageList[progressIndex].formatpushTime }}
            </div>
            <div class="dateText" v-if="stageList[progressIndex].popTime">
              出栏日期：  {{ stageList[progressIndex].formatpopTime }}
            </div>
          </div>
          <div class="baseInfocontainer">
            <div class="infoitem">
              <div class="key">养殖天数</div>
              <div class="value">{{ stageList[progressIndex].cycleDay }}</div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">
                养
                <div style="width: 6.5px" />
                殖
                <div style="width: 7px" />
                户
              </div>
              <div class="value">
                {{
                  stageList[progressIndex].breedingFarmer
                    ? stageList[progressIndex].breedingFarmer
                    : "个体户"
                }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">
                养
                <div style="width: 6.5px" />
                殖
                <div style="width: 7px" />
                场
              </div>
              <div class="value" style="color: #d82a1f"   @click="onClickFarm(stageList[2])">
                {{
                  stageList[2].farmManagerName
                    ? stageList[progressIndex].farmManagerName
                    : "个体户"
                }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">
                圈
                <div style="width: 6.5px" />
                舍
                <div style="width: 7px" />
                码
              </div>
              <div class="value">
                {{
                  stageList[progressIndex].farmManagerCircleHouse
                    ? stageList[progressIndex].farmManagerCircleHouse
                    : "个体户"
                }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">喂食饲料</div>
              <div class="value">
                {{ stageList[progressIndex].feedingName }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div class="infoitem">
              <div class="key">疫苗接种</div>
              <div class="value">
                {{ stageList[progressIndex].vaccineName }}
              </div>
            </div>
            <div class="infoLine"></div>
            <div
              class="openDialog"
              @click="showDialog(stageList[progressIndex].id)"
            >
              饲养情况
            </div>
          </div>
        </div>
        <div class="progressRight"></div>
      </div>
      <div class="bottom"> <img style="display: block;margin: 0 auto;width: 150px;" src="https://asset.fsytss.com/trace/wcj/icon_technical_support.png" alt=""></div>
    </div>
    <van-dialog
      v-model="show"
      confirm-button-text="关闭"
      confirm-button-color="#A1A1A1"
    >
      <div style="margin: 16px; font-weight: bold; text-align: center">
        饲养记录
      </div>
      <div
        style="
          height: 50vh;
          overflow-y: scroll;
          padding: 0 8px;
          font-size: 14px;
        "
      >
        <van-list
          v-model="loading"
          :finished="true"
          v-if="feedingRecordList.length > 0"
        >
          <div
            style="display: flex; width: 100%"
            v-for="(item, index) in feedingRecordList"
            :key="index"
          >
            <div
              style="
                flex: 1;
                border-bottom: 0.5px solid rgb(229, 229, 229);
                padding: 8px;
                margin: 4px 0;
              "
            >
              <div style="display: flex; color: #a1a1a1">
                <div
                  class="bgc"
                  style="
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: 13px;
                    padding: 6px 8px;
                    border-radius: 6px;
                    color: #d82a1f;
                    background-color: #f9edea;
                  "
                >
                  饲养日期
                </div>
                <div
                  style="
                    flex: 1;
                    color: #d82a1f;
                    font-weight: bold;
                    padding: 5px 0 5px 8px;
                    margin: auto 0;
                    text-align: right;
                  "
                >
                  {{ getFeedingTime(item.feedingTime) }}至{{ getFeedingTime(item.feedingEndTime) }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  color: #a1a1a1;
                  margin-top: 10px;
                  justify-content: space-between;
                "
              >
                <div style="width: 150px; padding-left: 8px">投喂饲料</div>
                <div
                  style="
                    color: #222222;
                    margin-left: 8px;
                    font-size: 13px;
                    color: #231815;
                    line-height: 20px;
                  "
                >
                  {{ item.feedingFood }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  color: #a1a1a1;
                  margin-top: 10px;
                "
              >
                <div style="padding-left: 8px">投喂频次</div>
                <div
                  style="
                    color: #222222;
                    margin-left: 8px;
                    font-size: 13px;
                    color: #231815;
                    line-height: 20px;
                  "
                >
                  {{ getFeedingFrequency(item.feedingFrequency) }}
                </div>
              </div>
              <!-- 巡检图片 -->
              <div
              v-if="item.imageUrl"
                style="
                  display: flex;
                  justify-content: space-between;
                  color: #a1a1a1;
                  margin-top: 10px;
                "
              >
                <div style="padding-left: 8px">巡检照片</div>
                <img @click="ImagePreview(item.imageUrl)" style="width: 25vw;height: 40vw;" :src="item.imageUrl" alt="">
              </div>
            </div>
          </div>
        </van-list>
        <van-empty
          class="custom-image"
          image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
          description="暂未上传数据"
          style="height: 100%"
          v-if="feedingRecordList.length <= 0"
        />
      </div>
    </van-dialog>
    <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>
     <!-- 养殖场弹出 -->
     <van-dialog
      v-model="showFarm"
      confirm-button-text="关闭"
      confirm-button-color="#A1A1A1"
      style="background: #34ac40"
    >
      <div
        style="
          margin: 16px;
          font-weight: bold;
          text-align: center;
          color: #ffffff;
        "
      >
        {{ farm ? farm.name : "" }}
      </div>
      <div
        style="
          height: 55vh;
          overflow-y: scroll;
          padding: 0 8px;
          font-size: 14px;
          background: linear-gradient(360deg, #f4f6f8 0%, #34ac40 100%);
        "
      >
        <div class="box" style="margin-top: 0">
          <div style="display: flex">
            <img
              src="../../assets/images/bias-double-dot.png"
              style="width: 18px; height: 18px"
            />
            <div style="line-height: 18px; margin-left: 5px; font-weight: bold">
              基础信息
            </div>
          </div>

          <div style="flex: 1; color: #a1a1a1">
            <div style="display: flex; margin-top: 4px">
              养殖容量
              <div
                style="
                  flex: 1;
                  color: #222222;
                  margin-left: 8px;
                  text-align: right;
                "
              >
                {{ farm.volume }}
              </div>
            </div>
            <div style="display: flex; margin-top: 4px">
              圈
              <div style="width: 7px" />
              舍
              <div style="width: 7px" />
              数
              <div
                style="
                  flex: 1;
                  color: #222222;
                  margin-left: 8px;
                  text-align: right;
                "
              >
                {{ farm.circleHouse }}个
              </div>
            </div>
            <div style="display: flex; margin-top: 4px">
              面积(m²)
              <div
                style="
                  flex: 1;
                  color: #222222;
                  margin-left: 8px;
                  text-align: right;
                "
              >
                {{ farm.acreage }}
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div style="color: #222222; font-weight: bold; margin-top: 8px">
            <div style="display: flex">
              <img
              src="../../assets/images/bias-double-dot.png"
                style="width: 18px; height: 18px"
              />
              <div
                style="line-height: 18px; margin-left: 5px; font-weight: bold"
              >
                养殖场简介
              </div>
            </div>
            <van-image
              width="100%"
              height="22vh"
              :src="farm ? farm.imageUrl : ''"
              style="margin-top: 8px"
            />
            <div
              style="
                color: #222222;
                font-size: 14px;
                margin-top: 8px;
                font-weight: normal;
              "
            >
              {{ farm.introduction }}
            </div>
          </div>
        </div>
        <div class="box">
          <div
            style="
              color: #222222;
              font-weight: bold;
              margin-top: 8px;
              margin-bottom: 12px;
            "
          >
            <div style="display: flex">
              <img
              src="../../assets/images/bias-double-dot.png"
                style="width: 18px; height: 18px"
              />
              <div
                style="line-height: 18px; margin-left: 5px; font-weight: bold"
              >
                定位信息
              </div>
            </div>
            <div
              id="farmMap"
              style="width: 100%; height: calc(320px / 16 * 9); margin-top: 8px"
            ></div>

            <div
              style="
                display: flex;
                color: #222222;
                font-weight: normal;
                margin-top: 8px;
              "
            >
              <div style="width: 42px">{{ "地址:" }}</div>
              <div style="flex: 1">{{ farm.address }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
  
  <script>
import Vue from "vue";
import { Image as VanImage, Dialog, Empty, List, Loading,ImagePreview } from "vant";
import { post } from "../../utils/http";
import moment from "moment";
Vue.use(VanImage).use(Dialog).use(Empty).use(List).use(Loading).use(ImagePreview);
export default {
  name: "HomeProductTraceComponent",
  props: [
    "totalStageNum",
    "first",
    "last",
    "stageList",
    "supplierAuth1",
    "supplierAuth2",
    "supplierAuth3",
    "supplierName",
  ],
  data() {
    return {
      show: false,
      feedingRecordList: [],
      loadingAll: false,
      loading: false,
      progressIndex: 0,
        farm: {},
      showFarm: false,
    };
  },
  methods: {
    ImagePreview(url){
      ImagePreview([url]);
    },
      onClickFarm(item) {
      console.log(item);
      if (!item.farmManagerId) {
        return;
      }
      this.loadingAll = true;
      const url =
        "/api/public/v2/manufacture/tracing/{farmManagerId}/farmManagerInfo".replace(
          "{farmManagerId}",
          item.farmManagerId
        );
      post(url, {}, false)
        .then((res) => {
          // this.vaccinumRecordList = res.data.breedingBatchStageVaccineList;

          this.farm = res.data;

          this.showFarm = true;

          setTimeout(() => {
            this.initFarmMap(); // 渲染地图
          }, 500);
        })
        .catch((err) => {
          Toast(err.message);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    initFarmMap() {
      let latitude = Number(this.farm.latitude);
      let longitude = Number(this.farm.longitude);

      console.log("initFarmMap = " + latitude + " ; " + longitude);

      if (this.farmMap) {
        this.farmMap.setCenter(new TMap.LatLng(latitude, longitude));
        this.farmMarkerLayer.updateGeometries([
          {
            styleId: "myStyle",
            id: "1",
            position: new TMap.LatLng(latitude, longitude),
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ]);

        return;
      }

      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.farmMap = new TMap.Map("farmMap", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });

      //创建并初始化MultiMarker
      this.farmMarkerLayer = new TMap.MultiMarker({
        map: this.farmMap, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        //点标记数据数组
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },
    goto(i) {
      this.progressIndex = i;
    },
    showDialog(stageId) {
      this.loadingAll = true;
      const url =
        "/api/public/v2/manufacture/tracing/{stageId}/stageItemInfo".replace(
          "{stageId}",
          stageId
        );
      post(url, {}, false)
        .then((res) => {
          this.feedingRecordList = res.data.breedingBatchStageFeedingList;

          this.showFeedingRecord = true;
          this.show = true;
        })
        .catch((err) => {
          Toast(err.message);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    onClickFirst() {
      this.$parent.onClickShowBusiness(this.first.trustList);
    },
    onClickFeedingRecord(item) {
      this.$parent.onClickShowFeedingRecord(item.id);
    },
    onClickVaccinumRecord(item) {
      this.$parent.onClickShowVaccinumRecord(item.id);
    },
    
    getFeedingTime(feedTime) {
      return moment(feedTime).format("yyyy-MM-DD");
      // return moment(startTime).format('yyyy-MM-DD') + "至" + moment(endTime).format('yyyy-MM-DD')
    },
    getFeedingFrequency(feedingFrequency) {
      if (feedingFrequency) {
        switch (feedingFrequency) {
          case "EVERYDAY":
            return "每日投喂";
          case "THREE_DAYS":
            return "三日一次";
          case "TWO_DAYS":
            return "两日一次";
          case "SINGLE_TIME":
            return "单次投喂";
          default:
            return "";
        }
      }
      return "";
    },
  },
  mounted() {
  },
};
</script>
  
<style lang="less" scoped>
.all {
  width: 100vw;
  background-image: linear-gradient(to bottom, #fe975b, #d63815);
  padding-top: 56px;
  .progress {
    padding: 0 28px;
    display: flex;
    .step1 {
      width: 76px;
      height: 76px;
      background: url("https://asset.fsytss.com/trace/wcj/icon_step_1.png") no-repeat
          center / cover;
      position: relative;

      .progressText {
        position: absolute;
        bottom: 10px;
        left: 9px;
        font-size: 15px;
        color: #f1733a;
      }
    }
    .step1Select {
      width: 76px;
      height: 76px;
      background: url("https://asset.fsytss.com/trace/wcj/icon_step_1s.png") no-repeat
          center / cover;
      position: relative;

      .progressText {
        position: absolute;
        bottom: 10px;
        left: 9px;
        font-size: 15px;
        color: #fff;
      }
    }
    .step2 {
      width: 76px;
      height: 76px;
      background: url("https://asset.fsytss.com/trace/wcj/icon_step_2.png") no-repeat
          center / cover;
      position: relative;

      .progressText {
        position: absolute;
        bottom: 10px;
        left: 9px;
        font-size: 15px;
        color: #f1733a;
      }
    }
    .step2Select {
      width: 76px;
      height: 76px;
      background: url("https://asset.fsytss.com/trace/wcj/icon_step_2s.png") no-repeat
          center / cover;
      position: relative;

      .progressText {
        position: absolute;
        bottom: 10px;
        left: 9px;
        font-size: 15px;
        color: #fff;
      }
    }
    .step3 {
      width: 76px;
      height: 76px;
      background: url("https://asset.fsytss.com/trace/wcj/icon_step_3.png") no-repeat
          center / cover;
      position: relative;

      .progressText {
        position: absolute;
        bottom: 10px;
        left: 9px;
        font-size: 15px;
        color: #f1733a;
      }
    }
    .step3Select {
      width: 76px;
      height: 76px;
      background: url("https://asset.fsytss.com/trace/wcj/icon_step_3s.png") no-repeat
          center / cover;
      position: relative;

      .progressText {
        position: absolute;
        bottom: 10px;
        left: 9px;
        font-size: 15px;
        color: #fff;
      }
    }
    .progressDot {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2px;
      .point {
        width: 4px; /* 点的直径 */
        height: 4px; /* 点的直径 */
        background-color: #ffe1c5; /* 点的颜色 */
        border-radius: 6px; /* 圆形 */
      }
    }
  }
  .stepDetailContainer {
    width: 100vw;
    margin-top: 18px;
    display: flex;
    justify-content: space-between;

    .progressLeft {
      width: 25px;
      height: 220px;
      background-color: red;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background-image: linear-gradient(to bottom, #eed3bd, #fdb9b8);
    }
    .stepDetail {
      width: 284px;
      position: relative;
      background: url("https://asset.fsytss.com/trace/wcj/bg_step_info_logo.png")
        no-repeat center / cover;
      border-radius: 6px;
      .img {
        width: 205px;
        height: 205px;
        border-radius: 205px;
        border: 1px solid red;
        overflow: hidden;
        margin: 0 auto;
        margin-top: 15px;
        img{

          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .date {
        width: 252px;
        height: 41px;
        margin: 0 auto;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: url("../../assets/images/project2/progressDate.png")
          no-repeat center / cover;
        .dateText {
          font-size: 13px;
          color: #ffffff;
          line-height: 16px;
          text-align: center;
        }
      }
      .baseInfocontainer {
        width: 240px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0 auto;
        .infoitem {
          display: flex;
          justify-content: space-between;
          font-size: 13px;
          color: #666666;
          line-height: 26px;
          .key {
            display: flex;
            line-height: 16px;
          }
          .value {
            width: 180px;
            text-align: right;
            line-height: 16px;
          }
        }
        .infoLine {
          // #f17036
          width: 240px;
          height: 1px;
          background-color: #f17036;
          margin: 6px 0;
        }
        .openDialog {
          width: 91px;
          height: 22px;
          margin: 0 auto;
          margin-top: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f1733a;
          border-radius: 4px;
          font-weight: 500;
          font-size: 14px;
          color: #ffffff;
        }
      }
      .gotoleft {
        position: absolute;
        width: 31px;
        height: 31px;
        bottom: 67px;
        left: -15px;
        background: url("https://asset.fsytss.com/trace/wcj/icon_goleft.png") no-repeat
          center / cover;
      }
      .gotoright {
        position: absolute;
        width: 31px;
        height: 31px;
        bottom: 67px;
        right: -15px;
        background: url("https://asset.fsytss.com/trace/wcj/icon_goright.png") no-repeat
          center / cover;
      }
    }
    .progressRight {
      width: 25px;
      height: 220px;
      background-color: red;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      background-image: linear-gradient(to bottom, #eed3bd, #fdb9b8);
    }
  }
  .bottom {
    margin-top: 32px;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    line-height: 20px;
    opacity: 0.8;
    // padding-bottom: 16px;
  }
}
.box {
  background: #fff;
  border-radius: 6px;
  padding: 8px;
  margin: 10px 0;

  & + .box {
    margin-top: 16px;
  }

  .title {
    padding-left: 25px;
    position: relative;
    margin-bottom: 8px;

    &::after,
    &::before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      background: #64b65b;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 5px;
      z-index: 2;
    }

    &::after {
      background: #cce8cb;
      z-index: 1;
      left: 4px;
      top: 1px;
    }
  }
}
</style>